<template>
    <div class="bg-gray-100 rounded-xl">
        <div class="px-3 py-2 flex items-center">
            <div class="flex-1 min-w-0 flex items-center">
                <div class="flex-none w-16 h-16 bg-white rounded-2xl">
                    <img 
                        v-if="form.image"
                        :src="form.image"
                        class="w-full h-full object-cover rounded-2xl"
                    />
                </div>

                <div class="ml-3 flex-1 min-w-0">
                    <p class="font-bold leading-tight">{{ form.name }}</p>
                    <p class="text-xl text-gray-500">{{ form.price }}€</p>
                </div>
            </div>

            <div class="ml-3 text-right">
                <InputNumber 
                    v-if="form.type === 'unique'"
                    v-model="form.quantity"
                    @update:modelValue="updateProduct"
                    class=""
                />

                <button 
                    v-else
                    class="p-0.5 transition-all duration-300 ease-in-out" 
                    :class="{ 'transform rotate-180': isOpen }"
                    @click="toggle"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/></svg>
                </button>
            </div>
        </div>

        <div 
            v-if="isOpen && form.type === 'daily'"
            class="px-3 py-2"
        >
            <div 
                v-if="structure?.length"
                class="space-y-2"
            >
                <ProductStructureItem
                    v-for="(category, index) in structure"
                    :key="index"
                    :category="category"
                    :date="date"
                    :selected="itemIds"
                    class="px-4 py-3 bg-white rounded-xl"
                    @select:item="onItemCheck"
                    @deselect:item="onItemUncheck"
                />

                <div class="mt-3 flex justify-center">
                    <div>
                        <p class="text-center text-gray-500">Cantidad</p>

                        <InputNumber 
                            v-model="form.quantity"
                            @update:modelValue="updateProduct"
                            class="mt-2"
                        />
                    </div>
                </div>
            </div>

            <div
                v-else
                class="mt-2"
            >
                <p class="text-gray-500">No hay items</p>
            </div>
        </div>
    </div>
</template>

<script>
    import { computed, ref, watch, watchEffect } from 'vue';

    import ProductStructureItem from "./ProductStructureItem.vue";
    import InputNumber from "@/components/input-number";
    
    import ProductCategory from '/src/api/endpoints/ProductCategory.js';
    import usePromise from '/src/composables/use-promise.js';

    import { format } from '/src/utils/dates.js';
    

    export default {
        name: 'ProductItem',
        components: {
            ProductStructureItem,
            InputNumber
        },
        props: {
            product: {
                type: Object,
                required: true
            },
            date: {
                type: Date,
                required: true
            },
        },
        emits: ['update:product'],
        setup(props, { emit }) {
            // form
            const form = ref({});
            const itemIds = ref([]);

            watchEffect(() => {
                form.value = {
                    ...props.product,
                    itemIds: []
                }
            });


            // toggle
            const isOpen = ref(false);
            function toggle() {
                isOpen.value = !isOpen.value;

                if (isOpen.value) {
                    form.value.quantity = 1;
                } else {
                    form.value.quantity = 0;
                    itemIds.value = [];
                }

                updateProduct();
            }

            watch(() => form.value.quantity, (quantity) => {
                if (quantity > 0) {
                    isOpen.value = true;
                } else {
                    isOpen.value = false;
                }
            });


            // get structure
            const {
                result: structure = [],
                use: getStructure
            } = usePromise(async () => {
                const list = await ProductCategory.all({
                    query: {
                        productId: props.product.id
                    }
                });

                return list.sort((a, b) => {
                    return a.order - b.order;
                });
            });

            getStructure();


            // update product
            function updateProduct() {
                emit('update:product', {
                    ...form.value,
                    itemIds: itemIds.value
                });
            }


            // items
            function onItemCheck(item) {
                if (!item) {
                    return;
                }

                itemIds.value.push(item.id);
                updateProduct();
            }

            function onItemUncheck(item) {
                if (!item) {
                    return;
                }

                const index = itemIds.value.indexOf(item.id);
                if (index >= 0) {
                    itemIds.value.splice(index, 1);

                    updateProduct();
                }
            }


            return {
                form,

                // toggle
                isOpen,
                toggle,

                // structure
                structure,

                // update product
                updateProduct,

                // items
                itemIds,
                onItemCheck,
                onItemUncheck,
            };
        }
    }
</script>