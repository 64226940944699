<template>
    <div class="inline-flex bg-white rounded-lg">
        <button 
            class="p-1 bg-gray-200 rounded-lg"
            @click="decrement"
        >
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4"/></svg>
        </button>
        
        <input 
            type="number"
            v-model.number="localValue"
            :min="min"
            :max="max"
            :step="step"
            class="bg-transparent w-10 text-center text-xl text-gray-600"
            @input="update(localValue)"
        />

        <button 
            class="p-1 bg-gray-200 rounded-lg"
            @click="increment"
        >
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"/></svg>
        </button>
    </div>
</template>

<script>
    import { ref, watchEffect } from 'vue';

    export default {
        name: 'InputNumber',
        props: {
            modelValue: {
                type: Number,
                default: 0
            },
            min: {
                type: Number,
                default: 0
            },
            max: {
                type: Number,
                default: 1e10
            },
            step: {
                type: Number,
                default: 1
            }
        },
        setup(props, { emit }) {
            const localValue = ref(props.modelValue);
            watchEffect(() => localValue.value = props.modelValue);

            function update() {
                emit('update:modelValue', localValue.value || 0);
            }

            function decrement() {
                localValue.value -= props.step;

                if (localValue.value < props.min) {
                    localValue.value = props.min;
                }

                update()
            }

            function increment() {
                localValue.value += props.step;

                if (localValue.value > props.max) {
                    localValue.value = props.max;
                }

                update()
            }

            return {
                localValue,
                update,
                decrement,
                increment
            };
        }
    }
</script>

<style scoped>
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        @apply appearance-none m-0;
    }
</style>