<template>
    <slot 
        :balance="balance"
    />
</template>

<script>
    import { ref, computed } from 'vue';
    import EmployeeBalance from "@/api/endpoints/EmployeeBalance.js";

    import { state } from '@/store';
    import emitter from "@/utils/emitter.js";

    export default {
        name: 'Balance',
        setup(props) {
            const balance = ref(0);
            const user = computed(() => state.user);

            async function getBalance() {
                balance.value = await EmployeeBalance.current(user.value.id);
            }

            getBalance();

            emitter.on("balance:change", () => {
                getBalance();
            });

            return {
                user,
                balance,
                getBalance,
            };
        },
    }
</script>