import EndpointPrivate from '../EndpointPrivate.js';
import DailyItem from './DailyItem.js';
import resolver from '@/utils/resolver.js';
import { format } from '@/utils/dates.js';

class Item extends EndpointPrivate {
	buildUrl({ param }) {
		return ['food', 'v1', 'items', param];
	}

	async afterRequest(response, request) {
		if (['paginate', 'all'].includes(request.action)) {
			response.data.data = await resolver(response.data.data, 'id', 'daily', value => {
				return DailyItem.all({
					query: {
						itemId: value,
                        date: format(request.query.date, 'yyyy-MM-dd')
					}
				})
			});
		}

        return super.afterRequest(response, request);
    }

}

export default new Item;