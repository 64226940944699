import EndpointPrivate from "../EndpointPrivate";

class Product extends EndpointPrivate {
	buildUrl({ param }) {
		return ['food', 'v1', 'products', param];		
	}
};

export default new Product;

