<template>
    <div class="px-4 py-3 bg-white rounded-xl">
        <p class="font-medium">{{ category.name || category.itemCategory?.name }}</p>

        <div 
            v-if="items?.length"
            class="mt-2 space-y-2"
        >
            <template
                v-for="item in items"
                :key="item.id"
            >
                <div
                    v-if="item.daily.quantity > 0"
                    class="flex items-center cursor-pointer"
                    :class="{ 'opacity-50': item.daily.remaining <= 0 }"
                >
                    <img 
                        :src="item.image"
                        class="w-12 h-12 flex-none rounded-2xl object-cover bg-gray-100"
                    />

                    <div class="ml-3 flex-1 min-w-0">
                        <p class="text-gray-500">{{ item.name }}</p>
                    </div>

                    <div class="ml-3">
                        <button 
                            :disabled="item.daily.remaining <= 0"
                            class="w-8 h-8 rounded-full flex justify-center items-center bg-gray-200"
                            :class="{
                                'bg-gray-200 text-gray-400': !isSelected(item),
                                'bg-green-400 text-white': isSelected(item)
                            }"
                            @click="selectItem(item)"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/></svg>
                        </button>
                    </div>                            
                </div>
            </template>
        </div>

        <div
            v-else
            class="mt-2"
        >
            <p class="text-gray-500">No hay items</p>
        </div>
    </div>
</template>

<script>
    import { ref, watch } from 'vue';
    import Item from '/src/api/endpoints/Item.js';
    import usePromise from '/src/composables/use-promise.js';


    export default {
        name: 'ProductStructureItem',
        props: {
            category: {
                type: Object,
                required: true
            },
            date: {
                type: Date,
                required: true
            },
            selected: {
                type: Array,
                default: () => []
            }
        },
        setup(props, { emit }) {
            // get items
            const {
                result: items = [],
                use: getItems
            } = usePromise(async () => {
                return Item.all({
                    query: {
                        categoryId: props.category.itemCategory.id,
                        date: props.date
                    }
                });
            });

            getItems();
            

            // select item
            const selected = ref();
            function selectItem(item) {
                if (selected.value) {
                    emit('deselect:item', selected.value);
                }

                selected.value = item;
                emit('select:item', item);
            }
            function isSelected(item) {
                return item.id === selected.value?.id;
            }

            // autocheck option
            watch(() => items.value, (list) => {
                const item = list.find(i => i.daily.remaining > 0);
                selectItem(item);
            });


            return {
                // get items
                items,

                // select item
                selected,
                selectItem,
                isSelected
            };
        }
    }
</script>