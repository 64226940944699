import asyncMap from './async-map.js';

export default async function (data, inputKey, outputKey, replacer) {
	return await asyncMap(data, async item => {
		const data = await replacer(item[inputKey]);

		return {
			...item,
			[outputKey]: data,
		};
	});
};

