import EndpointPrivate from "../EndpointPrivate";

class Section extends EndpointPrivate {
	buildUrl({ param }) {
		return ['food', 'v1', 'sections', param];		
	}
};

export default new Section;

