<template>
    <MainLayout>
        <div
            v-if="service" 
            class="mt-16 flex flex-col flex-1 bg-white rounded-t-3xl pb-20"
        >
            <div class="px-6 py-4 flex-1">
                <div class="-mt-20 flex flex-col items-center">
                    <Card :balance="employeeBalance"/>
                </div>

                <div class="mt-4 flex justify-between items-center">
                    <h1 class="text-3xl text-gray-800 font-semibold">
                        {{ service.name }}
                    </h1>

                    <DatePicker 
                        v-model="date"
                        v-slot="{ togglePopover }"
                    >
                        <button 
                            class="inline-flex items-center"
                            @click="togglePopover"
                        >
                            <span class="text-lg font-medium text-blue-400 capitalize">{{ formattedDate }}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/></svg>
                        </button>
                    </DatePicker>
                </div>

                <div class="mt-4 flex items-center bg-gray-100 rounded-xl">
                    <button 
                        class="p-2" 
                        :class="{ 
                            'text-gray-400': !hasPrev,
                            'text-gray-700': hasPrev,
                        }"
                        @click="prevSection"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/></svg>
                    </button>

                    <div class="flex-1 min-w-0 overflow-hidden">
                        <div 
                            class="flex transition-all duration-300 ease-in-out"
                            :style="`transform: translateX(${-sectionIndex}00%)`"
                        >
                            <div
                                v-for="section in sections"
                                :key="section.id"
                                class="flex-none w-full"
                            >
                                <p class="text-center font-bold text-gray-700">{{ section.name }}</p>
                            </div>
                        </div>
                    </div>

                    <button 
                        class="p-2" 
                        :class="{ 
                            'text-gray-400': !hasNext,
                            'text-gray-700': hasNext,
                        }"
                        @click="nextSection"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/></svg>
                    </button>
                </div>

                <div class="mt-6 space-y-2">
                    <ProductItem
                        v-for="product in products"
                        :key="product.id"
                        :product="product"
                        :date="date"
                        @update:product="updateProduct"
                    />
                </div>
            </div>

            <div class="text-center fixed inset-x-0 w-full bottom-0 px-6 py-4 bg-white bg-opacity-25 backdrop-filter backdrop-blur-sm">
                <span v-if="employeeBalance <= MIN_BUY_BALANCE" class="text-xs text-rose-500">
                    Recarga tu saldo para poder realizar la compra.
                </span>

                <button
                    class="px-4 py-3 w-full text-xl text-gray-200 rounded-xl"
                    :class="{ 'bg-gray-500': disabledButton, 'bg-gray-700': !disabledButton }"
                    :disabled="disabledButton"
                    @click="payConfirmation"
                >
                    Pay · €{{ price }}
                </button>
            </div>
        </div>
    </MainLayout>
</template>

<script>
    import { computed, ref, watch, onUnmounted, onMounted } from "vue";
    import { useRoute } from 'vue-router';

    import MainLayout from '@/layouts/main';
    import Card from '@/components/card';
    import ProductItem from './ProductItem.vue';

    import { DatePicker } from 'v-calendar';
    import Icon from "@/components/icon";
    import { 
        Dialog, 
        DialogTitle 
    } from "@/components/dialog";
    import BalanceValue from '@/components/balance-value';
    
    
    import usePromise from '@/composables/use-promise.js';
    import Qr from "@/api/endpoints/Qr.js";
    import Section from "@/api/endpoints/Section.js";
    import Product from "@/api/endpoints/Product.js";
    import Balance from "@/api/endpoints/Balance.js";
    
    import { alert } from '@/components/alerts';
    import { notify } from '@/components/notifications';

    import asynForEach from '@/utils/async-for-each.js';
    import emitter from '@/utils/emitter.js';
    import { beautify, formatRelative } from '@/utils/dates.js';
    import { state } from '@/store';
		import EmployeeBalance from '@/api/endpoints/EmployeeBalance';


    export default {
        name: "Service",
        components: {
            MainLayout,
            Card,
            ProductItem,
            DatePicker,
            Icon,
            Dialog,
            DialogTitle,
            BalanceValue
        },
        setup(props) {
            // route
            const route = useRoute();
            const MIN_BUY_BALANCE = 0;
            const qrId = computed(() => route.params.id);

            // qr
            const {
                loading: getServiceLoading,
                result: service,
                use: getService
            } = usePromise(async () => {
                return Qr.find(qrId.value);
            });

            getService();


            // date
            const date = ref(new Date);
            const formattedDate = computed(() => formatRelative(date.value, new Date));


            // sections
            const selectedSection = ref(null);
            const sectionIndex = ref(0);
            const {
                loading: getSectionsLoading,
                result: sections,
                use: getSections
            } = usePromise(async (id) => {
                return Section.all({ query: {
                    qrId: id || qrId.value
                } });
            });
            
            watch(() => service.value, async ({ id }) => {
                await getSections(id);
                
                if (sections.value.length > 0) {
                    selectedSection.value = sections.value[sectionIndex.value];
                }
            });

            const hasPrev = computed(() => sectionIndex.value > 0);
            function prevSection() {
                if (hasPrev.value) {
                    sectionIndex.value -= 1;
                }

                selectedSection.value = sections.value[sectionIndex.value];
            }

            const hasNext = computed(() => sectionIndex.value < sections.value?.length - 1);
            function nextSection() {
                if (hasNext.value) {
                    sectionIndex.value += 1;
                }

                selectedSection.value = sections.value[sectionIndex.value];
            }


            // products
            const {
                loading: getProductsLoading,
                result: products,
                use: getProducts
            } = usePromise(async (id) => {
                return Product.all({ query: {
                    sectionId: id || selectedSection.value?.id
                } });
            });
            
            watch(() => [selectedSection.value, date.value], async ([{ id }, date]) => {
                await getProducts(id);
            });

            function updateProduct(product) {                
                const index = products.value.findIndex(p => p.id === product.id);
                if (index >= 0) {
                    products.value[index] = product;
                }
            }


            // price
            const price = computed(() => {
                return products.value?.reduce((a, p) => a + p.price * (p.quantity || 0), 0).toFixed(2);
            });

            // quantity
            const quantity = computed(() => {
                return products.value?.reduce((a, p) => a + (p.quantity || 0), 0);
            });
            

            // payment
            const {
                loading: paymentLoading,
                use: pay
            } = usePromise(async () => {
                try {
                    const cart = products.value.filter(p => p.quantity > 0);
                    const balances = [];

                    await asynForEach(cart, async function(product) {
                        const amount = product.quantity * product.price;

                        const balance = await Balance.create({
                            employeeId: state.user.id,
                            type: "loss",
                            amount: -amount,
                            quantity: product.quantity,
                            productId: product.id,
                            itemIds: product.itemIds,
                            date: beautify(date.value)
                        });

                        balances.push(balance);
                    });

                    emitter.emit("balance:change");                

                    alert({
                        title: '¡Gracias por tu compra!',
                        text: `Tu compra de ${price.value}€ se ha realizado correctamente.`,
                        confirmLabel: 'Ok',
                        cancelLabel: 'Cancelar',
                        cancelButton: false,
                        confirm: async ({ close }) => {
                            const excludeList = [
                                'dbc148bb-ffb1-47b1-b2ab-8af6f79a557d', // buffet
                                // 'bb76514e-d283-4279-9497-ac8966173547' // tostadas
                            ];

                            if (qrId.value === '1795d491-9354-43a9-83d3-3801bca5fb54') { // qr cocina
                                balances.forEach(balance => {
                                    const section = balance.product.section;
                                    if (!excludeList.includes(section.id)) {
                                        timeConfirmation(balance);
                                    }
                                });
                            }
                            

                            products.value = [];
                            getProducts();

                            close();
                        }
                    });
                } catch (error) {
                    notify({
                        title: 'Payment error',
                        text: error
                    });
                }
            });

            function payConfirmation() {
                alert({
                    title: 'Confirmar pago',
                    text: `Se va a realizar un pago de ${price.value}€. Seguro que quieres continuar?`,
                    confirmLabel: 'Confirmar',
                    cancelLabel: 'Cancelar',
                    confirm: async ({ close }) => {
                        await pay();

                        close();
                    }
                });
            }

            function timeConfirmation(balance) {
                const product = balance.product.name; 

                alert({
                    title: 'Confirmar hora del pedido',
                    text: `¿Quieres indicar que empiecen a preparar tu "${product}"?`,
                    confirmLabel: 'Pedir ahora',
                    cancelLabel: 'Mas tarde',
                    confirm: async ({ close }) => {
                        await Balance.update({
                            ...balance,
                            status: 'requested'
                        });

                        close();
                    }
                });
            }

            const employeeBalance = ref(0);
            async function getBalance() {
                employeeBalance.value = await EmployeeBalance.current(state.user.id);
            }
            onMounted(() => {
                emitter.on("balance:change", getBalance);
            });
            onUnmounted(() => {
                emitter.off("balance:change", getBalance);
            });

            getBalance();


            const disabledButton = computed(() => {
                return employeeBalance.value <= MIN_BUY_BALANCE || paymentLoading.value || quantity.value === 0;
            });

            return {                
                // service
                qrId,
                service,

                // sections
                selectedSection,
                sectionIndex,
                sections,
                hasPrev,
                prevSection,
                hasNext,
                nextSection,

                // products
                products,
                updateProduct,

                // date
                date,
                formattedDate,

                // price
                price,

                // quantity
                quantity,

                // payment
                paymentLoading,
                pay,
                payConfirmation,

                // employee balance
                employeeBalance,
                disabledButton,
                MIN_BUY_BALANCE
            };
        },
    };
</script>